import React, { useState, useEffect } from 'react';
import { uploadFile } from '../../services/api';
import './Upload.css';

const UploadButton = ({ setFileUploaded, externalError }) => {
  const [uploadStatus, setUploadStatus] = useState('');

  useEffect(() => {
    if (externalError === 'Please select a file') {
      setUploadStatus(externalError);
    }
  }, [externalError]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const response = await uploadFile(file);
        setUploadStatus(response.message);
        setFileUploaded(true); // Notify parent component of successful upload
      } catch (error) {
        setUploadStatus(`Error: ${error.message}`);
        setFileUploaded(false); // Notify parent component of failed upload
      }
    }
  };

  const isErrorMessage = uploadStatus === 'Please select a file' || uploadStatus.startsWith('Error:');

  return (
    <div className="upload-button-card">
      <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
      {uploadStatus && (
        <div className={isErrorMessage ? 'message-container error-container' : 'message-container success-container'}>
          <p className={isErrorMessage ? 'error-message' : 'success-message'}>{uploadStatus}</p>
        </div>
      )}
    </div>
  );
};

export default UploadButton;
