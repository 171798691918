import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Login from './components/Auth/Login';
import StockFilters from './components/Filters/Output';
import ResultTables from './components/DCF/Results';
// import Register from './components/Auth/Register';
// import Profile from './components/Profile/Profile';
// import Practice from './components/Learning/Practice';
// import Showcase from './components/Learning/Showcase';
// import OnePlayerStory from './components/StoryMode/OnePlayerStory';
// import TwoPlayerStory from './components/StoryMode/TwoPlayerStory';
// import ProgressTracker from './components/Progress/ProgressTracker';
// import Feedback from './components/Progress/Feedback';
// import AdminDashboard from './components/Dashboard/AdminDashboard';



const App = () => {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Login />} /> */}
        <Route path="/filters" element={<StockFilters />} />
        <Route path="/dcf" element={<ResultTables />} />
      </Routes>
    </Router>
  );
};

export default App;