import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  return (
    <div className="header-container">
      <nav className="navbar">
        <img src="/images/Sinnet_Logo.png" alt="Company Logo" className="logo" />
        <div className="nav-links">
          <Link to="/filters">Stock Filters</Link>
          <Link to="/dcf">Discounted Cash Flows</Link>
        </div>
        <div className="auth">
          Username | Logout
        </div>
      </nav>
    </div>
  );
}

export default Header;
