import React from 'react';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
        &copy; {currentYear} Sinnet Capital. All rights reserved.
    </footer>
  );
}

export default Footer;
