import React, { useState } from 'react';
import './InputTable.css';

const InputTable = ({ onGenerateResults }) => {
  const [formData, setFormData] = useState({
    ticker: '',
    multiplier: '',
    discount_rate: '',
    growth_rate: '',
    noncontrolling_interest: 0,
    cash_st_investments: 0,
    incremental_working_capital: 0,
  });

  const [errors, setErrors] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = [];

    if (parseFloat(formData.growth_rate) < 0) {
      newErrors.push('Yearly Revenue Growth cannot be negative.');
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
    } else {
      setErrors([]);
      onGenerateResults(formData);
    }
  };

  const handleWheel = (event) => {
    if (event.target.type === 'number') {
      event.preventDefault();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="input-table">
      <div className="input-row">
        <label>Identifier:</label>
        <input type="text" name="ticker" value={formData.ticker} onChange={handleChange} required />
        <p id="unit">&nbsp;&nbsp;&nbsp;&nbsp;</p>
      </div>
      <div className="input-row">
        <label>Multiple Toggle:</label>
        <input type="number" name="multiplier" value={formData.multiplier} onChange={handleChange} onWheel={handleWheel} required />
        <p id="unit">x&nbsp;&nbsp;</p>
      </div>
      <div className="input-row">
        <label>Discount Rate:</label>
        <input type="number" name="discount_rate" value={formData.discount_rate} onChange={handleChange} onWheel={handleWheel} required />
        <p id="unit">%&nbsp;</p>
      </div>
      <div className="input-row">
        <label>Yearly Revenue Growth:</label>
        <input type="number" name="growth_rate" value={formData.growth_rate} onChange={handleChange} onWheel={handleWheel} required />
        <p id="unit">%&nbsp;</p>
      </div>
      <div className="input-row">
        <label>Non-Controlling Interest:</label>
        <p id="unit">$</p>
        <input 
          type="number" 
          name="noncontrolling_interest" 
          value={formData.noncontrolling_interest} 
          onChange={handleChange} 
          onWheel={handleWheel} 
          required 
        />
        <p id="unit">mil.</p>
      </div>
      <div className="input-row">
        <label>Cash and ST Investments:</label>
        <p id="unit">$</p>
        <input 
          type="number" 
          name="cash_st_investments" 
          value={formData.cash_st_investments} 
          onChange={handleChange} 
          onWheel={handleWheel} 
          required 
        />
        <p id="unit">mil.</p>
      </div>
      <div className="input-row">
        <label>Incremental Working Capital:</label>
        <p id="unit">$</p>
        <input 
          type="number" 
          name="incremental_working_capital" 
          value={formData.incremental_working_capital} 
          onChange={handleChange} 
          onWheel={handleWheel} 
          required 
        />
        <p id="unit">mil.</p>
      </div>
      <button id="input-button-dcf" type="submit">Generate Results</button>
      {errors.length > 0 && (
        <div className="error-messages">
          {errors.map((error, index) => (
            <p key={index} className="error">{error}</p>
          ))}
        </div>
      )}
    </form>
  );
};

export default InputTable;
