import React, { useState } from 'react';
import './Inputs.css';

const InputPanel = ({ onFilterChange }) => {
  const [minMarketCap, setMinMarketCap] = useState('');
  const [maxMarketCap, setMaxMarketCap] = useState('');
  const [minSharePrice, setMinSharePrice] = useState('');
  const [maxSharePrice, setMaxSharePrice] = useState('');
  const [minPSR, setMinPSR] = useState('');
  const [maxPSR, setMaxPSR] = useState('');
  const [minNetCash, setMinNetCash] = useState('');
  const [maxNetCash, setMaxNetCash] = useState('');
  const [minEvEbita, setMinEvEbita] = useState('');
  const [maxEvEbita, setMaxEvEbita] = useState('');
  const [minPriceToBook, setMinPriceToBook] = useState('');
  const [maxPriceToBook, setMaxPriceToBook] = useState('');
  const [minOperatingIncome, setMinOperatingIncome] = useState('');
  const [maxOperatingIncome, setMaxOperatingIncome] = useState('');
  const [minEv, setMinEv] = useState('');
  const [maxEv, setMaxEv] = useState('');
  const [minGrossMargin, setMinGrossMargin] = useState('');
  const [maxGrossMargin, setMaxGrossMargin] = useState('');

  const [openFilter, setOpenFilter] = useState({
    marketCap: false,
    sharePrice: false,
    PSR: false,
    netCash: false,
    evEbita: false,
    ptoB: false,
    operatingIncome: false,
    eV: false,
    gM: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onFilterChange({
      minMarketCap: minMarketCap ? minMarketCap * 1000000 : '',
      maxMarketCap: maxMarketCap ? maxMarketCap * 1000000 : '',
      minSharePrice,
      maxSharePrice,
      minPSR,
      maxPSR,
      minNetCash: minNetCash ? minNetCash * 1000000 : '',
      maxNetCash: maxNetCash ? maxNetCash * 1000000 : '',
      minEvEbita,
      maxEvEbita,
      minPriceToBook,
      maxPriceToBook,
      minOperatingIncome: minOperatingIncome ? minOperatingIncome * 1000000 : '',
      maxOperatingIncome: maxOperatingIncome ? maxOperatingIncome * 1000000 : '',
      minEv,
      maxEv,
      minGrossMargin,
      maxGrossMargin,
    });
  };

  const toggleFilter = (filterName) => {
    setOpenFilter((prev) => ({
      ...prev,
      [filterName]: !prev[filterName],
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div id="accordian-label" onClick={() => toggleFilter('marketCap')}>
          <h4>Market Cap</h4>
          <button type="button">
            {openFilter.marketCap ? '▲' : '▼'}
          </button>
        </div>
        {openFilter.marketCap && (
          <>
            <div>
              <label>
                $
                <input
                  type="number"
                  placeholder="min"
                  value={minMarketCap}
                  onChange={(e) => setMinMarketCap(e.target.value)}
                />
                million
              </label>
            </div>
            <div>
              <label>
                $
                <input
                  type="number"
                  placeholder="max"
                  value={maxMarketCap}
                  onChange={(e) => setMaxMarketCap(e.target.value)}
                />
                million
              </label>
            </div>
          </>
        )}
      </div>

      <div>
        <div id="accordian-label" onClick={() => toggleFilter('sharePrice')}>
          <h4>Share Price</h4>
          <button type="button">
            {openFilter.sharePrice ? '▲' : '▼'}
          </button>
        </div>
        {openFilter.sharePrice && (
          <>
            <div>
              <label>
                $
                <input
                  type="number"
                  placeholder="min"
                  value={minSharePrice}
                  onChange={(e) => setMinSharePrice(e.target.value)}
                />
              </label>
            </div>
            <div>
              <label>
                $
                <input
                  type="number"
                  placeholder="max"
                  value={maxSharePrice}
                  onChange={(e) => setMaxSharePrice(e.target.value)}
                />
              </label>
            </div>
          </>
        )}
      </div>

      <div>
        <div id="accordian-label" onClick={() => toggleFilter('PSR')}>
          <h4>PSR</h4>
          <button type="button">
            {openFilter.PSR ? '▲' : '▼'}
          </button>
        </div>
        {openFilter.PSR && (
          <>
            <div>
              <label>
                <input
                  type="number"
                  placeholder="min"
                  value={minPSR}
                  onChange={(e) => setMinPSR(e.target.value)}
                />
              </label>
            </div>
            <div>
              <label>
                <input
                  type="number"
                  placeholder="max"
                  value={maxPSR}
                  onChange={(e) => setMaxPSR(e.target.value)}
                />
              </label>
            </div>
          </>
        )}
      </div>

      <div>
        <div id="accordian-label" onClick={() => toggleFilter('netCash')}>
          <h4>Net cash from Operations</h4>
          <button type="button">
            {openFilter.netCash ? '▲' : '▼'}
          </button>
        </div>
        {openFilter.netCash && (
          <>
            <div>
              <label>
                $
                <input
                  type="number"
                  placeholder="min"
                  value={minNetCash}
                  onChange={(e) => setMinNetCash(e.target.value)}
                />
                million
              </label>
            </div>
            <div>
              <label>
                $
                <input
                  type="number"
                  placeholder="max"
                  value={maxNetCash}
                  onChange={(e) => setMaxNetCash(e.target.value)}
                />
                million
              </label>
            </div>
          </>
        )}
      </div>

      <div>
        <div id="accordian-label" onClick={() => toggleFilter('evEbita')}>
          <h4>EV/EBITDA</h4>
          <button type="button">
            {openFilter.evEbita ? '▲' : '▼'}
          </button>
        </div>
        {openFilter.evEbita && (
          <>
            <div>
              <label>
                <input
                  type="number"
                  placeholder="min"
                  value={minEvEbita}
                  onChange={(e) => setMinEvEbita(e.target.value)}
                />
              </label>
            </div>
            <div>
              <label>
                <input
                  type="number"
                  placeholder="max"
                  value={maxEvEbita}
                  onChange={(e) => setMaxEvEbita(e.target.value)}
                />
              </label>
            </div>
          </>
        )}
      </div>

      <div>
        <div id="accordian-label" onClick={() => toggleFilter('ptoB')}>
          <h4>Price to Book Value</h4>
          <button type="button">
            {openFilter.ptoB ? '▲' : '▼'}
          </button>
        </div>
        {openFilter.ptoB && (
          <>
            <div>
              <label>
                <input
                  type="number"
                  placeholder="min"
                  value={minPriceToBook}
                  onChange={(e) => setMinPriceToBook(e.target.value)}
                />
              </label>
            </div>
            <div>
              <label>
                <input
                  type="number"
                  placeholder="max"
                  value={maxPriceToBook}
                  onChange={(e) => setMaxPriceToBook(e.target.value)}
                />
              </label>
            </div>
          </>
        )}
      </div>

      <div>
        <div id="accordian-label" onClick={() => toggleFilter('operatingIncome')}>
          <h4>Operating Income over last 12 months</h4>
          <button type="button">
            {openFilter.operatingIncome ? '▲' : '▼'}
          </button>
        </div>
        {openFilter.operatingIncome && (
          <>
            <div>
              <label>
                $
                <input
                  type="number"
                  placeholder="min"
                  value={minOperatingIncome}
                  onChange={(e) => setMinOperatingIncome(e.target.value)}
                />
                million
              </label>
            </div>
            <div>
              <label>
                $
                <input
                  type="number"
                  placeholder="max"
                  value={maxOperatingIncome}
                  onChange={(e) => setMaxOperatingIncome(e.target.value)}
                />
                million
              </label>
            </div>
          </>
        )}
      </div>

      <div>
        <div id="accordian-label" onClick={() => toggleFilter('eV')}>
          <h4>Enterprise Value</h4>
          <button type="button">
            {openFilter.eV ? '▲' : '▼'}
          </button>
        </div>
        {openFilter.eV && (
          <>
            <div>
              <label>
                <input
                  type="number"
                  placeholder="min"
                  value={minEv}
                  onChange={(e) => setMinEv(e.target.value)}
                />
              </label>
            </div>
            <div>
              <label>
                <input
                  type="number"
                  placeholder="max"
                  value={maxEv}
                  onChange={(e) => setMaxEv(e.target.value)}
                />
              </label>
            </div>
          </>
        )}
      </div>

      <div>
        <div id="accordian-label" onClick={() => toggleFilter('gM')}>
          <h4>Gross Margin %</h4>
          <button type="button">
            {openFilter.gM ? '▲' : '▼'}
          </button>
        </div>
        {openFilter.gM && (
          <>
            <div>
              <label>
                <input
                  type="number"
                  placeholder="min"
                  value={minGrossMargin}
                  onChange={(e) => setMinGrossMargin(e.target.value)}
                />
              </label>
            </div>
            <div>
              <label>
                <input
                  type="number"
                  placeholder="max"
                  value={maxGrossMargin}
                  onChange={(e) => setMaxGrossMargin(e.target.value)}
                />
              </label>
            </div>
          </>
        )}
      </div>

      <button type="submit">Filter</button>
    </form>
  );
};

export default InputPanel;