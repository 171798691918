import React, { useState, useMemo } from 'react';
import InputPanel from './Inputs';
import './Output.css';
import Header from '../Header/Header.js';
import Footer from '../Footer/Footer.js';

const StockFilters = () => {
  const [stocks, setStocks] = useState([]);
  const [sortConfig, setSortConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(false);  // Loading state

  const handleFilterChange = async (filters) => {
    const {
      minMarketCap, maxMarketCap, minSharePrice, maxSharePrice, minPSR, maxPSR,
      minNetCash, maxNetCash, minEvEbita, maxEvEbita, minPriceToBook, maxPriceToBook,
      minOperatingIncome, maxOperatingIncome, minEv, maxEv, minGrossMargin, maxGrossMargin
    } = filters;

    const params = {
      min_market_cap: minMarketCap,
      max_market_cap: maxMarketCap,
      min_share_price: minSharePrice,
      max_share_price: maxSharePrice,
      min_revenue_to_market_cap_ratio: minPSR,
      max_revenue_to_market_cap_ratio: maxPSR,
      min_net_cash: minNetCash,
      max_net_cash: maxNetCash,
      min_ev_ebitda: minEvEbita,
      max_ev_ebitda: maxEvEbita,
      min_price_to_book_value: minPriceToBook,
      max_price_to_book_value: maxPriceToBook,
      min_operating_income: minOperatingIncome,
      max_operating_income: maxOperatingIncome,
      min_ev: minEv,
      max_ev: maxEv,
      min_gm: minGrossMargin,
      max_gm: maxGrossMargin
    };

    const queryString = new URLSearchParams(params).toString();

    setIsLoading(true);  // Set loading to true before fetching

    try {
      const response = await fetch(`https://dhanya08.pythonanywhere.com/filter_stocks?${queryString}`, {
        method: 'GET',
      });

      const responseText = await response.text();
      let data;
      try {
        const sanitizedResponseText = responseText.replace(/Infinity/g, "null");
        data = JSON.parse(sanitizedResponseText);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        setIsLoading(false);  // Set loading to false in case of error
        return;
      }

      setStocks(data);
    } catch (error) {
      console.error('Error fetching stock data:', error);
    } finally {
      setIsLoading(false);  // Set loading to false after fetching
    }
  };

  const isNull = (value) => {
    return value === null;
  }

  const formatNumber = (num) => {
    if (isNaN(num) || num === null) {
      return '-';
    }
    return parseFloat(num).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const sortedStocks = useMemo(() => {
    let sortableStocks = [...stocks];
    if (sortConfig !== null) {
      sortableStocks.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableStocks;
  }, [stocks, sortConfig]);

  const requestSort = (key, direction) => {
    setSortConfig({ key, direction });
  };

  return (
    <div id="main">
      <Header />
      <div id="container">
        <div id="filters">
          <h2>Filters</h2>
          <InputPanel onFilterChange={handleFilterChange} />
        </div>
        <div id="output">
          {isLoading ? (
            <div className="loading-indicator">
              <p>Loading...</p>
            </div>
          ) : (
            <>
              <h2>Showing {sortedStocks.length} Stocks</h2>
              {sortedStocks.length > 0 ? (
                <div className="table-container">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          Symbol
                          <span className="sort-arrows">
                            <button onClick={() => requestSort('Symbol', 'ascending')}>▲</button>
                            <button onClick={() => requestSort('Symbol', 'descending')}>▼</button>
                          </span>
                        </th>
                        <th>
                          Market Cap / $
                          <span className="sort-arrows">
                            <button onClick={() => requestSort('Market Cap', 'ascending')}>▲</button>
                            <button onClick={() => requestSort('Market Cap', 'descending')}>▼</button>
                          </span>
                        </th>
                        <th>
                          Share Price / $
                          <span className="sort-arrows">
                            <button onClick={() => requestSort('Share Price', 'ascending')}>▲</button>
                            <button onClick={() => requestSort('Share Price', 'descending')}>▼</button>
                          </span>
                        </th>
                        <th>
                          PSR
                          <span className="sort-arrows">
                            <button onClick={() => requestSort('PSR', 'ascending')}>▲</button>
                            <button onClick={() => requestSort('PSR', 'descending')}>▼</button>
                          </span>
                        </th>
                        <th>
                          Net Cash / $
                          <span className="sort-arrows">
                            <button onClick={() => requestSort('Net Cash From Operations', 'ascending')}>▲</button>
                            <button onClick={() => requestSort('Net Cash From Operations', 'descending')}>▼</button>
                          </span>
                        </th>
                        <th>
                          EV/EBITDA
                          <span className="sort-arrows">
                            <button onClick={() => requestSort('EV/EBITDA', 'ascending')}>▲</button>
                            <button onClick={() => requestSort('EV/EBITDA', 'descending')}>▼</button>
                          </span>
                        </th>
                        <th>
                          Price to Book
                          <span className="sort-arrows">
                            <button onClick={() => requestSort('Price to Book', 'ascending')}>▲</button>
                            <button onClick={() => requestSort('Price to Book', 'descending')}>▼</button>
                          </span>
                        </th>
                        <th>
                          Operating Income / $
                          <span className="sort-arrows">
                            <button onClick={() => requestSort('Operating Income', 'ascending')}>▲</button>
                            <button onClick={() => requestSort('Operating Income', 'descending')}>▼</button>
                          </span>
                        </th>
                        <th>
                          EV
                          <span className="sort-arrows">
                            <button onClick={() => requestSort('EV', 'ascending')}>▲</button>
                            <button onClick={() => requestSort('EV', 'descending')}>▼</button>
                          </span>
                        </th>
                        <th>
                          Gross Margin %
                          <span className="sort-arrows">
                            <button onClick={() => requestSort('Gross Margins', 'ascending')}>▲</button>
                            <button onClick={() => requestSort('Gross Margins', 'descending')}>▼</button>
                          </span>
                        </th>
                        <th>
                          YTD Return %
                          <span className="sort-arrows">
                            <button onClick={() => requestSort('YTD Change', 'ascending')}>▲</button>
                            <button onClick={() => requestSort('YTD Change', 'descending')}>▼</button>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedStocks.map((stock, i) => (
                        <tr key={i}>
                          <td>{stock.Symbol}</td>
                          <td>{formatNumber(stock["Market Cap"])}</td>
                          <td>{formatNumber(stock["Share Price"])}</td>
                          <td>{formatNumber(stock.PSR)}</td>
                          <td>{formatNumber(stock["Net Cash From Operations"])}</td>
                          <td>{formatNumber(stock["EV/EBITDA"])}</td>
                          <td>{formatNumber(stock["Price to Book"])}</td>
                          <td>{formatNumber(stock["Operating Income"])}</td>
                          <td>{formatNumber(stock.EV)}</td>
                          <td>{formatNumber(stock["Gross Margins"])}</td>
                          <td>{formatNumber(stock["YTD Change"])}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p>No stocks found</p>
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StockFilters;
