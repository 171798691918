import React, { useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import UploadButton from './Upload';
import InputTable from './InputTable';
import './Results.css';

const ResultTables = () => {
  const [results, setResults] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileError, setFileError] = useState('');
  const [loading, setLoading] = useState(false);  // Loading state

  const fetchDcfResults = async (formData) => {
    const queryString = Object.keys(formData)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`)
      .join('&');

    setLoading(true);  // Start loading

    try {
      const response = await fetch(`https://dhanya08.pythonanywhere.com/dcf?${queryString}`);
      const textResponse = await response.text();
      const sanitizedText = textResponse.replace(/NaN/g, 'null');
      const data = JSON.parse(sanitizedText);
      setResults(data);
    } catch (error) {
      console.error('Error fetching or processing DCF results:', error);
    } finally {
      setLoading(false);  // End loading
    }
  };

  const handleGenerateResults = (formData) => {
    if (!fileUploaded) {
      setFileError('Please select a file');
      return;
    }
    setFileError('');
    fetchDcfResults(formData);
  };

  const formatValue = (value) => {
    if (value === null || value === "N/A") return "N/A";
    return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <div className="results-page">
      <Header />
      <div className="results-content">
        <div className="left-panel">
          <div className="card upload-card">
            <h3>Upload Excel Sheet</h3>
            <UploadButton setFileUploaded={setFileUploaded} externalError={fileError}/>
          </div>
          <div className="card input-card">
            <h3>Inputs</h3>
            <InputTable onGenerateResults={handleGenerateResults} />
          </div>
        </div>
        <div className="right-panel">
          {loading ? (
            <div className="loading-indicator">
              <p>Loading...</p>
            </div>
          ) : (
            results ? (
              <div className="results-tables">
                <h3 className="table-header">Free Cash Flow</h3>
                <table className="result-table">
                  <thead>
                    <tr>
                      <th> (All figures in millions of USD except per share items) </th>
                      {Object.keys(results["Free Cash Flow"]).map((year) => (
                        <th key={year}>{year}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(results["Free Cash Flow"]["2023"]).map((label) => (
                      <tr key={label}>
                        <td>{label}</td>
                        {Object.keys(results["Free Cash Flow"]).map((year) => (
                          <td key={year}>
                            {results["Free Cash Flow"][year][label] !== null
                              ? formatValue(results["Free Cash Flow"][year][label])
                              : "N/A"}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <h3 className="table-header">EV/LTM EBITDA Data</h3>
                <table className="result-table">
                  <tbody>
                    {Object.entries(results["EV and LTM EBITDA"]).map(([key, value]) => (
                      <tr key={key}>
                        <td>{key}</td>
                        <td>{value !== null ? formatValue(value) : "N/A"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <h3 className="table-header">PV Calculation w/ EV/EBITDA Exit Multiple</h3>
                <table className="result-table">
                  <tbody>
                    {Object.entries(results["PV Calculation"]).map(([key, value]) => (
                      <tr key={key}>
                        <td>{key}</td>
                        <td>{value !== null ? formatValue(value) : "N/A"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <h3 className="table-header">Price Per Share Sensitivity Analysis</h3>
                <table className="result-table">
                  <thead>
                    <tr>
                      <th></th>
                      {Object.keys(
                        results["PPS Sensitivity "][
                          Object.keys(results["PPS Sensitivity "])[0]
                        ]
                      ).map((pct) => (
                        <th key={pct}>{(parseFloat(pct) * 100).toFixed(1) + "%"}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(results["PPS Sensitivity "]).map((multiple) => (
                      <tr key={multiple}>
                        <td>{multiple}</td>
                        {Object.values(results["PPS Sensitivity "][multiple]).map(
                          (value, index) => (
                            <td key={index}>{value !== null ? formatValue(value) : "N/A"}</td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No results to display</p>
            )
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResultTables;
