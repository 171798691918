// import axios from 'axios';

// const API_URL = 'http://localhost:5000';

// export const uploadFile = async (file) => {
//   const formData = new FormData();
//   formData.append('file', file);

//   try {
//     await axios.post(`${API_URL}/upload`, formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data'
//       }
//     });
//   } catch (error) {
//     console.error('Error uploading file:', error);
//   }
// };

// export const fetchDcfResults = async () => {
//   try {
//     const response = await axios.get(`${API_URL}/dcf-results`);
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching DCF results:', error);
//   }
// };
export const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await fetch('https://dhanya08.pythonanywhere.com/upload', {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };
  
  export const fetchDcfResults = async () => {
    // Simulate an API call with mock data
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
            "ev_ltm_ebitda_json": {
                "currentPrice": 214.29,
                "enterpriseValue": 3359875530752,
                "ltmEbitda": 335987553075.2,
                "sharesOutstanding": 15334099968
            },
            "free_cash_flow_json": {
                "2022": {
                "Capex of Revenue": 1,
                "Capital Expenditures": 1,
                "Capital Expenditures % of Revenue": NaN,
                "Depr. & Amort.": 2,
                "Depreciation & Amortization": NaN,
                "Discount Factor": 0.09090909090909091,
                "Discount Rate": 10,
                "Discounted Free Cash Flow": NaN,
                "EBITDA": 44.7,
                "EBITDA Margin": 44.7,
                "Growth Rate": NaN,
                "Incremental Working Capital": 200,
                "Less: Capital Expenditures": NaN,
                "Less: Incremental Working Capital": NaN,
                "Net Operating Profit After-Tax (1)": 1,
                "Plus: Depreciation & Amortization": NaN,
                "Total Revenue": 1,
                "Total Revenue Growth Rate": NaN,
                "Unlevered Free Cash Flow": -198
                },
                "2023": {
                "Capex of Revenue": 2,
                "Capital Expenditures": 2,
                "Capital Expenditures % of Revenue": NaN,
                "Depr. & Amort.": null,
                "Depreciation & Amortization": NaN,
                "Discount Factor": 0.008264462809917356,
                "Discount Rate": 10,
                "Discounted Free Cash Flow": NaN,
                "EBITDA": 49.269,
                "EBITDA Margin": 24.6345,
                "Growth Rate": 1.0,
                "Incremental Working Capital": 200,
                "Less: Capital Expenditures": NaN,
                "Less: Incremental Working Capital": NaN,
                "Net Operating Profit After-Tax (1)": 2,
                "Plus: Depreciation & Amortization": NaN,
                "Total Revenue": 2,
                "Total Revenue Growth Rate": NaN,
                "Unlevered Free Cash Flow": null
                },
                "2024": {
                "Capex of Revenue": 3,
                "Capital Expenditures": 3,
                "Capital Expenditures % of Revenue": NaN,
                "Depr. & Amort.": 1.653,
                "Depreciation & Amortization": NaN,
                "Discount Factor": 0.0007513148009015778,
                "Discount Rate": 10,
                "Discounted Free Cash Flow": NaN,
                "EBITDA": 46.6696,
                "EBITDA Margin": 15.556533333333334,
                "Growth Rate": 0.5,
                "Incremental Working Capital": 200,
                "Less: Capital Expenditures": NaN,
                "Less: Incremental Working Capital": NaN,
                "Net Operating Profit After-Tax (1)": 3,
                "Plus: Depreciation & Amortization": NaN,
                "Total Revenue": 3,
                "Total Revenue Growth Rate": NaN,
                "Unlevered Free Cash Flow": -198.347
                },
                "2025": {
                "Capex of Revenue": 4,
                "Capital Expenditures": 4,
                "Capital Expenditures % of Revenue": NaN,
                "Depr. & Amort.": 1.614,
                "Depreciation & Amortization": NaN,
                "Discount Factor": 6.830134553650706e-05,
                "Discount Rate": 10,
                "Discounted Free Cash Flow": NaN,
                "EBITDA": 61.6148,
                "EBITDA Margin": 15.4037,
                "Growth Rate": 0.3333333333333333,
                "Incremental Working Capital": 200,
                "Less: Capital Expenditures": NaN,
                "Less: Incremental Working Capital": NaN,
                "Net Operating Profit After-Tax (1)": 4,
                "Plus: Depreciation & Amortization": NaN,
                "Total Revenue": 4,
                "Total Revenue Growth Rate": NaN,
                "Unlevered Free Cash Flow": -198.386
                },
                "2026": {
                "Capex of Revenue": 5,
                "Capital Expenditures": 5,
                "Capital Expenditures % of Revenue": NaN,
                "Depr. & Amort.": null,
                "Depreciation & Amortization": NaN,
                "Discount Factor": 6.209213230591551e-06,
                "Discount Rate": 10,
                "Discounted Free Cash Flow": NaN,
                "EBITDA": null,
                "EBITDA Margin": null,
                "Growth Rate": 0.25,
                "Incremental Working Capital": 200,
                "Less: Capital Expenditures": NaN,
                "Less: Incremental Working Capital": NaN,
                "Net Operating Profit After-Tax (1)": 5,
                "Plus: Depreciation & Amortization": NaN,
                "Total Revenue": 5,
                "Total Revenue Growth Rate": NaN,
                "Unlevered Free Cash Flow": null
                },
                "2027": {
                "Capex of Revenue": 6,
                "Capital Expenditures": 6,
                "Capital Expenditures % of Revenue": NaN,
                "Depr. & Amort.": null,
                "Depreciation & Amortization": NaN,
                "Discount Factor": 5.644739300537774e-07,
                "Discount Rate": 10,
                "Discounted Free Cash Flow": NaN,
                "EBITDA": null,
                "EBITDA Margin": null,
                "Growth Rate": 0.2,
                "Incremental Working Capital": 200,
                "Less: Capital Expenditures": NaN,
                "Less: Incremental Working Capital": NaN,
                "Net Operating Profit After-Tax (1)": 6,
                "Plus: Depreciation & Amortization": NaN,
                "Total Revenue": 6,
                "Total Revenue Growth Rate": NaN,
                "Unlevered Free Cash Flow": null
                },
                "2028": {
                "Capex of Revenue": 7,
                "Capital Expenditures": 7,
                "Capital Expenditures % of Revenue": NaN,
                "Depr. & Amort.": null,
                "Depreciation & Amortization": NaN,
                "Discount Factor": 5.1315811823070673e-08,
                "Discount Rate": 10,
                "Discounted Free Cash Flow": NaN,
                "EBITDA": null,
                "EBITDA Margin": null,
                "Growth Rate": 0.16666666666666666,
                "Incremental Working Capital": 200,
                "Less: Capital Expenditures": NaN,
                "Less: Incremental Working Capital": NaN,
                "Net Operating Profit After-Tax (1)": 7,
                "Plus: Depreciation & Amortization": NaN,
                "Total Revenue": 7,
                "Total Revenue Growth Rate": NaN,
                "Unlevered Free Cash Flow": null
                }
            },
            "pps_sensitivity_json": {
                "9.0": {
                "-0.09999994868418818": -6.8207455505222905,
                "-0.04999994868418818": -6.8207455505222905,
                "5.1315811823070673e-08": -6.8207455505222905,
                "0.050000051315811825": -6.8207455505222905,
                "0.10000005131581183": -6.8207455505222905
                },
                "9.5": {
                "-0.09999994868418818": -6.8207455505222905,
                "-0.04999994868418818": -6.8207455505222905,
                "5.1315811823070673e-08": -6.8207455505222905,
                "0.050000051315811825": -6.8207455505222905,
                "0.10000005131581183": -6.8207455505222905
                },
                "10.0": {
                "-0.09999994868418818": -6.8207455505222905,
                "-0.04999994868418818": -6.8207455505222905,
                "5.1315811823070673e-08": -6.8207455505222905,
                "0.050000051315811825": -6.8207455505222905,
                "0.10000005131581183": -6.8207455505222905
                },
                "10.5": {
                "-0.09999994868418818": -6.8207455505222905,
                "-0.04999994868418818": -6.8207455505222905,
                "5.1315811823070673e-08": -6.8207455505222905,
                "0.050000051315811825": -6.8207455505222905,
                "0.10000005131581183": -6.8207455505222905
                },
                "11.0": {
                "-0.09999994868418818": -6.8207455505222905,
                "-0.04999994868418818": -6.8207455505222905,
                "5.1315811823070673e-08": -6.8207455505222905,
                "0.050000051315811825": -6.8207455505222905,
                "0.10000005131581183": -6.8207455505222905
                }
            },
            "pv_calculation_json": {
                "Sum of discounted cash flows": 0,
                "Total Debt": 104590000128,
                "equityValuePerShare": -6.8207455505222905,
                "premiumToCurrentPrice": -1.0318295093122511,
                "terminalValue": null,
                "totalEquityValue": -104589994128
            }  
        });
      }, 1000);
    });
  };
  